import React from 'react'
import SEO from "../../components/atoms/seo"

import Layout from '../../components/organisms/layout'
import Heading from '../../components/molecules/heading'
import Title from '../../components/atoms/title'
import Link from '../../components/atoms/link'


const StyleguidePage = () => (
  <Layout>
    <SEO title='Styleguide' />
    <Heading 
        title='Styleguide'
        weight='light'
    />
    <div className='p-styleguide'>
      <Link url='./colors' classNames='p-styleguide__link'>
        <Title
          type='h3'
          title='Colors'
          align='center'
          color='dark'
          weight='bold'
        />
      </Link>
      <Link url='./typography' classNames='p-styleguide__link'>
        <Title
          type='h3'
          title='Typography'
          align='center'
          color='dark'
          weight='bold'
        />
      </Link>
      <Link url='./components/' classNames='p-styleguide__link'>
        <Title
          type='h3'
          title='Components'
          align='center'
          color='dark'
          weight='bold'
        />
      </Link>
    </div>
  </Layout>
)

export default StyleguidePage
